$padding-base: 16px;

.App {
  text-align: center;
  width: 100%;
  max-width: 740px;
  padding: 50px;
  .commonHover {
    &:hover {
      border-color: #46535f;
      box-shadow: 0 0 7px #216798ba;
    }
  }
  .title {
    font-size: 24px;
    margin-bottom: $padding-base;
  }
  textarea, input {
    caret-color: aliceblue;
    color: white;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: $padding-base;
    background: #22282d;
    border-radius: 4px;
    border: 1px solid #363f47;
    &:focus {
      outline: none;
    }
    @extend .commonHover;
  }
  ::placeholder {
    color: #c8c9ca;
  }
  .textarea {
    padding: 0.5 * $padding-base;
  }
  .keyRow {
    display: flex;
    .key {
      height: 40px;
      padding: 0 0.5 * $padding-base;
    }
    .eye {
      font-size: 20px;
      margin-left: -33px;
      margin-top: 5px;
      cursor: pointer;
      user-select: none;
    }
  }
  .commonBtn {
    border-radius: 4px;
    background-color: #363f47;
    color: #acbbc7;
    border: 1px solid #363f47;
    cursor: pointer;
    &:active {
      background-color: #313B43FF;
    }
    @extend .commonHover;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: $padding-base;
    .encode {
      @extend .commonBtn;
      height: 40px;
      width: 45%;
    }
  }
  .resTitle {
    width: 100%;
    text-align: left;
    margin-top: 24px;
  }
  .res {
    white-space: pre-wrap;
    text-align: left;
    margin-top: 16px;
  }
}
