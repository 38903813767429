#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
body {
  background: #2d343a;
  color: rgb(205, 217, 229);
  font-family: Arial, sans-serif;
}
